import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

export default defineNuxtRouteMiddleware(async (to) => {
    await callOnce(async () => {
        const RootStore = useRootStore()
        const AuthStore = useAuthStore()

        const {
            buildHeaders,
            baseURL,
            endpoints: {
                general: { general },
            },
        } = useApiConfig()

        const config = useRuntimeConfig()

        if (config.public.variant === 'megadescuentos') {
            await AuthStore.authInit(to)
        }
        const headers = buildHeaders(AuthStore.SessionToken)

        const layoutData = await $fetch<Api.Responses.General>(general.path, {
            method: 'GET',
            headers,
            baseURL,
        }).catch((err) => console.log('General data fetch: ', err.data))

        const siteHeadband = await $fetch<Api.Responses.SiteHeadband>(general.links_headband, {
            method: 'GET',
            headers,
            baseURL,
        }).catch((err) => console.log('General headband fetch: ', err.data))

        if (layoutData) RootStore.setLayoutData(layoutData)

        if (siteHeadband) RootStore.setHeadbandData(siteHeadband)
    })
})
