export default {
    profile: {
        path: '/v1/user/profile',
    },
    acceptTerms: {
        path: '/v1/user/accept-terms/_ID_',
    },
    updatePassword: {
        path: '/v1/user/update-password',
        rrss: '/v1/user/update-password-rrss',
    },
    updateAvatar: {
        path: '/v1/user/update-avatar',
    },
    updateProfile: {
        path: '/v1/user/update-profile/_ID_',
    },
    deleteAccount: {
        path: '/v1/user/close-account/_ID_',
    },
    verifyAccount: {
        path: '/v1/auth/verify',
    },
    requestAccountVerification: {
        path: '/v1/user/send-verification',
    },
    emailPreferences: {
        path: '/v1/preferences',
    },
    webPreferences: {
        path: '/v1/preferences/web',
    },
}
