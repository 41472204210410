import langMega from '../lang/megadescuentos-lang-es'
import langJ from '../lang/jdescuentos-lang-es'
import langOK from '../lang/okdescontos-lang-pt'
import langB from '../lang/bchollos-lang-es'

const processVariant =
    (process.env.VARIANT as 'megadescuentos' | 'jdescuentos' | 'bchollos') || 'megadescuentos'

const processEnvironment = (process.env.ENVIRONMENT as 'development' | 'production') || 'production'

const siteLanguage = {
    megadescuentos: {
        env: 'es' as 'es',
        code: 'es-MX' as 'es-MX',
    },
    jdescuentos: {
        env: 'es' as 'es',
        code: 'es-CL' as 'es-CL',
    },
    okdescontos: {
        env: 'pt' as 'pt',
        code: 'pt-BR' as 'pt-BR',
    },
    bchollos: {
        env: 'es' as 'es',
        code: 'es-ES' as 'es-ES',
    },
}
const langs = {
    megadescuentos: langMega,
    jdescuentos: langJ,
    okdescontos: langOK,
    bchollos: langB,
}

const SocialAuth = {
    megadescuentos: {
        facebook: '1359619487847467',
        google: '637255019386-9srhlm038l7ehlbu3kidb65kvteq35nq.apps.googleusercontent.com',
    },
    jdescuentos: {
        facebook: '1559315064892855',
        google: '748915547428-oaopllu83c87pg0kvhl28sgg079s1h3r.apps.googleusercontent.com',
    },
    okdescontos: {
        // cambiar
        facebook: '',
        google: '',
    },
    bchollos: {
        // cambiar
        facebook: '3434624623517469',
        google: '712863410714-ktod9so97d4vnt5p80c96qpilibk8u1g.apps.googleusercontent.com',
    },
}

const siteStaticRoutesToIndex = (variant: 'megadescuentos' | 'jdescuentos' | 'bchollos') => {
    return [
        {
            url: '/' + langs[variant].routes.about_us,
            priority: 0.1,
            changefreq: 'monthly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.rules,
            priority: 0.1,
            changefreq: 'monthly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.blog,
            priority: 0.7,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        ...(variant === 'megadescuentos' || variant === 'jdescuentos'
            ? [
                  {
                      url: '/' + langs[variant].routes.share,
                      priority: 0.1,
                      changefreq: 'monthly',
                      lastmod: new Date(),
                      _sitemap: 'statics',
                  },
              ]
            : []),
        {
            url: '/' + langs[variant].routes.contact,
            priority: 0.1,
            changefreq: 'monthly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.coupons,
            priority: 0.6,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.events,
            priority: 0.7,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.expired,
            priority: 0.7,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        ...(variant === 'megadescuentos'
            ? [
                  {
                      url: '/' + langs[variant].routes.extension,
                      priority: 0.1,
                      changefreq: 'monthly',
                      lastmod: new Date(),
                      _sitemap: 'statics',
                  },
              ]
            : []),
        ...(variant === 'megadescuentos' || variant === 'jdescuentos' || 'bchollos'
            ? [
                  {
                      url: '/' + langs[variant].routes.forum,
                      priority: 0.7,
                      changefreq: 'daily',
                      lastmod: new Date(),
                      _sitemap: 'statics',
                  },

                  {
                      url: '/' + langs[variant].routes.register,
                      priority: 0.1,
                      changefreq: 'monthly',
                      lastmod: new Date(),
                      _sitemap: 'statics',
                  },
              ]
            : []),
        {
            url: '/' + langs[variant].routes.faq,
            priority: 0.1,
            changefreq: 'monthly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.free,
            priority: 0.7,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.novelties,
            priority: 0.5,
            changefreq: 'weekly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/' + langs[variant].routes.offers,
            priority: 0.6,
            changefreq: 'daily',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
        {
            url: '/',
            priority: 1,
            changefreq: 'hourly',
            lastmod: new Date(),
            _sitemap: 'statics',
        },
    ]
}

const siteFavicon = '/favicon.png'

const GTMKeys = {
    megadescuentos: 'GTM-NXGZNXP',
    jdescuentos: 'GTM-MW6XLWRN',
    okdescontos: 'GTM-W9TV8JGC',
    bchollos: 'GTM-N25ZD8Z7',
}

const siteName = {
    megadescuentos: 'MEGAdescuentos',
    jdescuentos: 'Jdescuentos',

    bchollos: 'Bchollos',
}

const siteOrigin = {
    megadescuentos: 'https://www.megadescuentos.com',
    jdescuentos: 'https://www.jdescuentos.cl',
    okdescontos: 'https://www.okdescontos.com.br',
    bchollos: 'https://www.bchollos.es',
}
const siteApiOrigin = {
    megadescuentos: 'https://admin.megadescuentos.com/api',
    jdescuentos: 'https://admin.jdescuentos.cl/api',
    okdescontos: 'https://admin.okdescontos.com.br/api',
    bchollos: 'https://admin.bchollos.es/api',
}

const siteFinalHost = {
    megadescuentos: 'www.megadescuentos.com',
    jdescuentos: 'www.jdescuentos.cl',
    okdescontos: 'www.okdescontos.com.br',
    bchollos: 'www.bchollos.es',
}

const googleSiteVerification = {
    megadescuentos: 'S1_so8-10-mK2mV-PWMBZjr3D2m4AJ07N0w_iJIyl-Y',
    jdescuentos: '',
    okdescontos: 'JOXyC0ZuvdK5RXMi7V1eKYXIJpGN03BZD3mXVWdvXsI',
    bchollos: 'bi9t1osp8emNaj7rz1jHW-Ur0ObgTMPMn6PZ3F7aHzs',
}
const irSiteVerificationToken = {
    megadescuentos: '457539947',
    jdescuentos: '',
    okdescontos: '-378293540',
    bchollos: '-378293540',
}

const verifyAdmitad = {
    megadescuentos: '',
    jdescuentos: '',
    okdescontos: '',
    bchollos: '87c5608d9e',
}

const lomadeeVerification = {
    megadescuentos: '',
    jdescuentos: '',
    okdescontos: '23361522',
    bchollos: '',
}

const impactSiteVerification = {
    megadescuentos: '',
    jdescuentos: '',
    okdescontos: '',
    bchollos: 'b9c38ce6-ac32-4915-8af3-6dc8d1923527',
}
const sharesales = {
    megadescuentos: '',
    jdescuentos: '',
    okdescontos: '',
    bchollos: 'GNVBNDMX183803279',
}
const defaultMetaTags = (variant: typeof processVariant) => [
    ...(impactSiteVerification[variant]
        ? [
              {
                  name: 'impact-site-verification',
                  content: impactSiteVerification[variant],
              },
          ]
        : []),
    ...(sharesales[variant]
        ? [
              {
                  name: 'sharesales',
                  content: sharesales[variant],
              },
          ]
        : []),
    ...(googleSiteVerification[variant]
        ? [
              {
                  name: 'google-site-verification',
                  content: googleSiteVerification[variant],
              },
          ]
        : []),
    ...(irSiteVerificationToken[variant]
        ? [
              {
                  name: 'ir-site-verification-token',
                  content: irSiteVerificationToken[variant],
              },
          ]
        : []),
    ...(verifyAdmitad[processVariant]
        ? [
              {
                  name: 'verify-admitad',
                  content: verifyAdmitad[processVariant],
              },
          ]
        : []),
    ...(lomadeeVerification[processVariant]
        ? [
              {
                  name: 'lomadee-verification',
                  content: lomadeeVerification[processVariant],
              },
          ]
        : []),
    {
        hid: 'og:image',
        property: 'og:image',
        content: siteOrigin[variant] + `/assets/jpg/${variant}/preview.jpg`,
    },
    {
        hid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: siteOrigin[variant] + `/assets/jpg/${variant}/preview.jpg`,
    },
    {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: siteOrigin[variant] + `/assets/jpg/${variant}/preview.jpg`,
    },
    {
        hid: 'og:site_name',
        property: 'og:site_name',
        content: siteName[variant],
    },
    {
        hid: 'og:type',
        property: 'og:type',
        content: 'website',
    },
    {
        hid: 'og:locale',
        property: 'og:locale',
        content: siteLanguage[variant].code,
    },
    {
        hid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary',
    },
]

const siteSitemapsPrefixes = (variant: typeof processVariant) => ({
    general: {
        novelties: `/${langs[variant].routes.novelties}/`,
        articles: `/${langs[variant].routes.blog}/`,
        events: `/${langs[variant].routes.events}/`,
    },
    stores: `/${langs[variant].routes.brands}/`,
    categories: `/${langs[variant].routes.categories}/`,
    chats: `/${langs[variant].routes.forum}/`,
    offers: `/${langs[variant].routes.offers}/`,
    coupons: `/${langs[variant].routes.coupons}/`,
})

const digidipKeys = {
    megadescuentos: 'megadescuentoscom',
    bchollos: 'bcholloses',
    jdescuentos: '',
    okdescontos: 'okdescontoscombr',
}
const sentry = {
    bchollos: 'https://411fd953766843963c3de97b3f68c778@o867892.ingest.sentry.io/4505879160619008',
    okdescontos: 'https://ebc054b80d1a4d747656c3e4afdc448c@o867892.ingest.us.sentry.io/4507529794879488',
    megadescuentos: 'https://50b124a4aae841199bd38d9b6acc2924@o867892.ingest.sentry.io/6521172',
    jdescuentos: `https://c4abf2365e604f288c0ce14604d9400d@o867892.ingest.us.sentry.io/4505187948756992`,
}
const gtmId = {
    bchollos: '',
    okdescontos: 'G-LDB3WB60H3',
    megadescuentos: '',
    jdescuentos: '',
}
const cookieBot = {
    bchollos: '1e3472d7-1c4c-4b8d-800b-0204441d4713',
    okdescontos: '89eb64b0-45f1-4dda-aa4f-fe87d25d5239',
    megadescuentos: '',
    jdescuentos: '',
}

const ahrefs = {
    megadescuentos: 'pvDLzlXBgrhy2v4H7xuGWg',
    jdescuentos: 'poryQFkj+m9x9R9I4r+9KQ',
    bchollos: 'E7VrIFeVl6y8/Q02TWbmeA',
    okdescontos: '',
}

export const variantConfig = {
    siteFavicon,
    siteVariant: processVariant,
    siteFinalHost: siteFinalHost[processVariant],
    siteName: siteName[processVariant],
    siteOrigin: siteOrigin[processVariant],
    GTMKeys,
    cookieBot,
    gtmId,
    ahrefs,
    siteLangCode: siteLanguage,
    metaTags: defaultMetaTags,
    sentryDns: sentry,
    siteSitemapsPrefixes,
    siteStaticRoutesToIndex,
    siteLangs: langs,
    digidip: digidipKeys,
    socialAuthKeys: SocialAuth,
    siteApiOrigin,
    processEnvironment,
}
