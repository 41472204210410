/* import * as Sentry from '@sentry/vue' */
import assets from '~/config/assets'

/* Main Site Config */
import constants from '~/config/constants'
import { variantConfig } from '~/config/variant'

export default defineNuxtPlugin(() => {
    const { public: config } = useRuntimeConfig()
    const lang = variantConfig.siteLangs[config.variant as 'megadescuentos' | 'jdescuentos']

    const openCoupon = (slug: string, storeSlug = '', landing = lang.routes.brands) => {
        if (storeSlug) {
            window.open(
                `${process.env.NODE_ENV === 'production' ? config.origin : location.origin}/${landing}/${storeSlug}?slug=${slug}`,
                '_blank',
            )
            location.href = `${process.env.NODE_ENV === 'production' ? config.origin : location.origin}/open/${slug}`
        } else {
            window.open(
                `${process.env.NODE_ENV === 'production' ? config.origin : location.origin}/open/${slug}`,
                '_blank',
            )
        }
    }
    const openOffer = (id: number) => {
        window.open(
            `${process.env.NODE_ENV === 'production' ? config.origin : location.origin}/go/${id}`,
            '_blank',
        )
    }
    return {
        provide: {
            assets: assets(config.variant),
            lang,
            constants,
            openers: {
                openCoupon,
                openOffer,
            },
        },
    }
})
