export default defineNuxtPlugin(() => {
    const router = useRouter()

    router.onError((error) => {
        const ignoreErrors = [
            'Failed to fetch dynamically imported module',
            'Importing a module script failed',
            'Unable to preload CSS',
            '_nuxt/builds/meta',
        ]

        if (ignoreErrors.some((errorMessage) => error.message.includes(errorMessage))) {
            window.location.reload()
        }
    })
})
