import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()

    const {
        public: { origin, sentry_dsn, sentry_environment, processEnvironment },
    } = useRuntimeConfig()

    if ((processEnvironment === 'production' && process.env.NODE_ENV === 'production') || !sentry_dsn) {
        Sentry.init({
            app: nuxtApp.vueApp,
            dsn: sentry_dsn,
            environment: sentry_environment,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],

            // Configure this whole part as you need it!

            tracesSampleRate: 0.2, // Change in prod

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', origin],

            replaysSessionSampleRate: 0, // Change in prod
            replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
        })
    }
})
