import endpoints from '../config/endpoints/indexs'

export const useApiConfig = () => {
    const { public: config } = useRuntimeConfig()

    const buildHeaders = (
        credentials?: string | null,
        contentType?: 'application/json' | 'multipart/form-data',
    ) => {
        const headers = {} as { [x: string]: string }
        headers['Origin'] = config.origin
        headers['Accept'] = 'application/json'

        contentType && (headers['Content-Type'] = contentType)
        credentials && (headers['Authorization'] = `Bearer ${credentials}`)

        return headers
    }
    return {
        buildHeaders,
        baseURL: config.apiOrigin,
        endpoints: {
            ...endpoints,
            authentication: endpoints.authentication(config.apiOrigin),
        },
    }
}
