export default (variant: string) => {
    const png = '/assets/png/'
    const svg = '/assets/svg/'
    const jpg = '/assets/jpg/'

    const primaryColorSVGsPath = svg + (variant + '/primary/')
    const secondaryColorSVGsPath = svg + (variant + '/secondary/')

    const variantPngPath = png + variant + '/'
    const variantJpgPath = jpg + variant + '/'

    return {
        social: {
            twitterAuthor: svg + 'twitter-solid-black.svg',
            instagramAuthor: svg + 'instagram-solid-black.svg',
            linkedinAuthor: svg + 'linkedin.svg',
            facebook: svg + 'facebook.svg',
            telegram: svg + 'telegram.svg',
            twitter: svg + 'twitter.svg',
            whatsapp: svg + 'whatsapp.svg',
            instagramWhite: svg + 'instagram-white.svg',
            facebookNotCircular: svg + 'facebook-not-circular.svg',
            telegramNotCircular: svg + 'telegram-not-circular.svg',
            twitterNotCircular: svg + 'twitter-not-circular.svg',
            twitterNotCircularWhite: svg + 'twitter-not-circular-white.svg',
            whatsappNotCircular: svg + 'whatsapp-not-circular.svg',
            messenger: svg + 'messenger.svg',
            instagram: svg + 'instagram.svg',
            apple: svg + 'apple.svg',
            googleAccount: svg + 'google-account.svg',
            facebookAccount: svg + 'facebook-account.svg',
            googlePlay: svg + 'google-play.svg',
            tiktok: svg + 'tiktok.svg',
            youtube: svg + 'youtube.svg',
            other: svg + 'other-social.svg',
            website: svg + 'website-social.svg',
            telegramFullImage: png + 'telegram-full-image.png',
            telegramTag: svg + 'telegram-tag.svg',
        } /* 
        social: {
            facebook: svg + 'social/' + 'facebook.svg',
            telegram: svg + 'social/' + 'telegram.svg',
            twitter: svg + 'social/' + 'twitter.svg',
            whatsapp: svg + 'social/' + 'whatsapp.svg',
            tiktok: svg + 'social/' + 'tiktok.svg',
            youtube: svg + 'social/' + 'youtube.svg',
            instagram: svg + 'social/' + 'instagram.svg',
        }, */,
        brand: {
            footerLogo: '/assets/png/' + variant + '/logo-footer.png',
            headerLogo: png + variant + '/logo-light.png',
            headerLogoLetter: png + variant + '/logo-letter-light.png',
            loaderLogoLetter: png + variant + '/logo-letter.png',
            favicon: `/favicon/${variant}.ico`,
        },
        auth: {
            login: {
                city: svg + 'city-auth-illustration-login.svg',
                fem: svg + 'fem-auth-illustration-login.svg',
                masc: svg + 'masc-auth-illustration-login.svg',
                people: svg + 'people-auth-illustration-login.svg',
                modalImage: png + 'modal-login-image.png',
                icons: {
                    chat: png + 'item-login-chat.png',
                    gift: png + 'item-login-gift.png',
                    people: png + 'item-login-people.png',
                },
            },
            register: {
                city: svg + 'city-auth-illustration-register.svg',
                fem: svg + 'fem-auth-illustration-register.svg',
                masc: svg + 'masc-auth-illustration-register.svg',
                people: svg + 'people-auth-illustration-register.svg',
                submit: svg + 'modal-email-submit.svg',
                modalImage: png + 'modal-register-image.png',
            },
            recovery: {
                city: svg + 'city-auth-illustration-recovery.svg',
                one: svg + 'one-auth-illustration-recovery.svg',
                two: svg + 'two-auth-illustration-recovery.svg',
                modalImage: png + 'modal-reset-image.png',
                modalBg: png + 'modal-reset-bg.png',
                emailWay: png + 'modal-digits-image.png',
                passwordWay: png + 'modal-new-pass-image.png',
                success: png + 'modal-reset-success-image.png',
                success2: png + 'modal-reset-success-image-2.png',
            },
        },
        share: {
            preview: '/preview.jpg',
            stepper: {
                first: svg + 'share-stepper-first.svg',
                second: svg + 'share-stepper-second.svg',
                third: svg + 'share-stepper-third.svg',
                firstInactive: svg + 'share-stepper-first-inactive.svg',
                secondInactive: svg + 'share-stepper-second-inactive.svg',
                thirdInactive: svg + 'share-stepper-third-inactive.svg',
            },
            lastStep: {
                cuponAndOffer: svg + 'cupon-and-offer-share-step-3-illustration.svg',
                survey: svg + 'survey-share-step-2-illustration.svg',
                chat: svg + 'chat-share-step-2-illustration.svg',
            },
        },
        surveys: {
            banner: png + 'banner-surveys.png',
            one: png + 'illustration-one-surveys.png',
            two: png + 'illustration-two-surveys.png',
            default: svg + 'surveys-default-illustration.svg',
        },
        search: {
            head: svg + 'head-search.svg',
        },
        error: {
            number: svg + '404.svg',
            cone: svg + 'cone.svg',
        },
        misc: {
            ribbonRegister: svg + 'ribbon.svg',
            contactIcon: svg + 'contact-icon.svg',
            buttonIcon: svg + 'button-icon.svg',
            buttonBend: svg + 'icon-bend-button.svg',
            elipse: png + 'elipse.png',
            buttonArrow: png + 'right-arrow.png',
            buttonDownload: svg + 'download-icon.svg',
            buttonDownloadPurple: svg + variant + '/primary/download-purple-icon.svg',
            face1Emoji: svg + 'face-1-emoji.svg',
            face2Emoji: svg + 'face-2-emoji.svg',
            clockEmoji: svg + 'clock-emoji.svg',
            face3Emoji: svg + 'face-3-emoji.svg',
            dancingWomanEmoji: svg + 'dancing-woman-emoji.svg',
            redBolt: svg + 'progress-bolt-red.svg',
            pinkAlert: svg + 'progress-alert-pink.svg',
            greenCheck: svg + 'progress-check-green.svg',
        },
        screenshots: {
            extensionLandingXXL: '/assets/png/' + variant + '/extension-landing-background-xxl.png',
            extensionLandingXL: '/assets/png/' + variant + '/extension-landing-background-xl.png',
            extensionLandingLG: '/assets/png/' + variant + '/extension-landing-background-lg.png',
            extensionLandingMD: '/assets/png/' + variant + '/extension-landing-background-md.png',
            extensionLandingSM: '/assets/png/' + variant + '/extension-landing-background-sm.png',
            extensionLandingMB: '/assets/png/' + variant + '/extension-landing-background-mb.png',
        },
        blogs: {
            phone: svg + 'phone-blogs.svg',
            woman: svg + 'woman-blogs.svg',
            shop: svg + 'shopping-blogs.svg',
            shelter: svg + 'shelter-blogs.svg',
            megaphone: svg + 'megaphone-blogs.svg',
        },
        illustration: {
            dashSummary: svg + 'dashboard-summary.svg',
            dashOffer: svg + 'dashboard-offer.svg',
            dashGift: svg + 'dashboard-gift.svg',
            dashChats: svg + 'dashboard-chats.svg',
            bannerSidebar1: variantJpgPath + 'banner-sidebar-1.jpg',
            bannerSidebar2: variantJpgPath + 'banner-sidebar-2.jpg',
            bannerSidebar3: variantJpgPath + 'banner-sidebar-3.jpg',
            bannerSidebar4: variantJpgPath + 'banner-sidebar-4.jpg',
            bannerSidebar5: variantJpgPath + 'banner-sidebar-5.jpg',
            bannerSidebar6: variantJpgPath + 'banner-sidebar-6.jpg',
            homepageHeader: png + 'homepage-header-illustration.png',
            uninstall: svg + 'uninstall-illustration.svg',
            waveOpen: svg + 'wave-open.svg',
            likeSprite: png + 'heart-sprite.png',
            newMessage: svg + 'new-message.svg',
            bannerAbout: svg + 'banner-about.svg',
            bannerExtension: svg + 'extension-banner.svg',
            bannerExtensionApp: svg + 'extension-download-banner.svg',
            extensionImage: svg + 'extension-image.svg',
            extensionImageFree: svg + 'extension-image-free.svg',
            extensionImageCupon: svg + 'extension-image-cupon.svg',
            extensionImageRegister: svg + 'extension-image-register.svg',
            extensionImageApp: svg + 'extension-image-app.svg',
            extensionImageShop: svg + 'extension-image-shop.svg',
            dashboardNoOffers: svg + 'dashboard-no-deals.svg',
            dashboardNoComments: svg + 'dashboard-no-comments.svg',
            dashboardHeader: svg + 'dashboard-header-background.svg',
            dashboardTab: svg + 'dashboard-tabs-background.svg',
            dashboardDiscountsSaved: svg + 'dashboard-discounts-saved.svg',
            dashboardSettings: svg + 'dashboard-settings.svg',
            dashboardGift: svg + 'dashboard-gift2.svg',
            login: png + 'login-illustration.png',
            register: png + 'register-illustration.png',
            unauthorized: svg + 'unauthorized-illustration.svg',
            searchNotFound: png + 'search-not-found-illustration.png',
            searchFailed: svg + 'search-failed-illustration.svg',
            welcomeIcons: svg + 'welcome-icons.svg',
            welcomeMex: svg + 'welcome-mex-illustration.svg',
            coupons: svg + 'coupons-illustration.svg',
            home: png + 'home-illustration.png',
            thumbsup: svg + 'thumbs-up-illustration.svg',
            firstGrade: svg + 'first-grade.svg',
            share: {
                offer: svg + variant + '/offer-share-illustration.svg',
                coupon: svg + variant + '/coupon-share-illustration.svg',
                survey: svg + variant + '/survey-share-illustration.svg',
                chat: svg + variant + '/chat-share-illustration.svg',
                newoffer: svg + 'offer-new-share-illustration.svg',
                newcoupon: svg + 'coupon-new-share-illustration.svg',
            },
            timeBanner: png + 'time-banner.png',
            shoppingBag: svg + 'shopping-bag.svg',
            megaphone: svg + 'megaphone.svg',
            ourVision: svg + 'our-vision.svg',
            ourTeam: svg + 'our-team.svg',

            aboutUs: {
                megadescuentos: {
                    bannerAboutUs1: variantPngPath + 'banner-about-us-1.png',
                    bannerAboutUs2: variantPngPath + 'banner-about-us-2.png',
                    bannerAboutUs3: variantPngPath + 'banner-about-us-3.png',
                    bannerAboutUs4: variantPngPath + 'banner-about-us-4.png',
                    aboutPeople1: variantPngPath + 'about-people-1.png',
                    aboutPeople2: variantPngPath + 'about-people-2.png',
                    aboutPeople3: variantPngPath + 'about-people-3.png',
                    aboutPeople4: variantPngPath + 'about-people-4.png',
                },
                jdescuentos: {
                    bannerAboutUs1: variantPngPath + 'banner-about-us-1.png',
                    bannerAboutUs2: variantPngPath + 'banner-about-us-2.png',
                    aboutPeople1: variantPngPath + 'about-people-1.png',
                    aboutPeople2: variantPngPath + 'about-people-2.png',
                },
                okdescontos: {
                    bannerAboutUs1: variantPngPath + 'about-us-banner-1.png',
                    bannerAboutUs2: variantPngPath + 'about-us-banner-2.png',
                    aboutPeople1: variantPngPath + 'about-us-people-1.png',
                    aboutPeople2: variantPngPath + 'about-us-people-2.png',
                },
                bchollos: {
                    bannerAboutUs1: variantPngPath + 'banner-about-us-1.png',
                    bannerAboutUs2: variantPngPath + 'banner-about-us-2.png',
                    bannerAboutUs3: variantPngPath + 'banner-about-us-3.png',
                    aboutPeople1: variantPngPath + 'about-people-1.png',
                    aboutPeople2: variantPngPath + 'about-people-2.png',
                },
            },

            notificationsEmpty: svg + 'notifications-empty.svg',
            popularOffer: svg + 'popular-offer.svg',
            publishedSuccess: svg + 'published-success.svg',
            offerExpired: svg + 'offer-expired.svg',
            comment: svg + 'comment-illustration.svg',
            threadPublishSuccess: svg + 'thread-publish-success.svg',
            newBlogEntry: svg + 'new-blog-entry.svg',
            coinPurpleAndRed: svg + 'coin-purple-and-red.svg',
            coinBlueAndRed: svg + 'coin-blue-and-red.svg',
            coinLightBlueAndRed: svg + 'coin-light-blue-and-red.svg',
            coinRed: svg + 'coin-red.svg',
            coinGreenAndRed: svg + 'coin-green-and-red.svg',
            coinBlue: svg + 'coin-blue.svg',
            coinDefault: svg + 'coin-default.svg',
            news: svg + 'news-page.svg',
            onetemp: svg + 'onetemp.svg',
            lessthanfivecomments: svg + 'less-than-five-comments.svg',
            morethanfivecomments: svg + 'more-than-five-comments.svg',
            commentRejected: svg + 'comment-rejected.svg',
            expiredOffer: svg + 'expired-offer.svg',
            banned: svg + 'banned-notification.svg',
            rejected: svg + 'rejected-notification.svg',
            acceptedGreen: svg + 'accepted-green.svg',
            cookies: svg + 'cookies-illustration.svg',
            cookiesAdjust: svg + 'cookies-adjust-illustration.svg',
            extensionUninstalledBgGreen: png + 'extension-uninstalled.png',
            extensionUninstalledOffer: svg + 'extension-uninstalled-offer.svg',
            eventImage: svg + 'event-image.svg',
            eventDefault: svg + 'event-default.svg',
            megaBrands: svg + 'mega-brands.svg',
            megaCategories: svg + 'mega-categories.svg',
            megaCommunity: svg + 'mega-comunidad.svg',
            megaCoupons: svg + 'mega-cupones-image.svg',
            megaOfertas: svg + 'mega-ofertas.svg',
            noDiscountsFound: svg + 'no-discounts-found.svg',
            noDiscountsFoundTwo: svg + 'no-discounts-found-2.svg',
            coinsJoinFree: svg + variant + '/coins-join-free.svg',
            coinMegaDescuentos: svg + 'coin-mega-descuentos.svg',
            coinPesosSymbol: svg + 'coin-pesos-symbol.svg',
            coinMegaDescuentos2: svg + 'coin-mega-descuentos-2.svg',
            coinPesosMDsymbol: svg + 'coin-md-pesos-symbol.svg',
            coinPesosMDsymbol2: svg + 'coin-md-pesos-symbol-2.svg',
            blog: {
                user1: svg + 'blog-user-1.svg',
                user2: svg + 'blog-user-2.svg',
                user3: svg + 'blog-user-3.svg',
            },
            banner: {
                coin: svg + 'coin-banner-illustration.svg',
                twitter: svg + 'twitter-banner-illustration.svg',
                instagram: svg + 'instagram-banner-illustration.svg',
                mega: svg + 'mega-banner-illustration.svg',
            },
            cta: {
                facebook: {
                    logo: png + 'facebook-cta-image.png',
                    tag: svg + 'facebook-cta-tag.svg',
                },
                whatsapp: {
                    bg: svg + 'whatsapp-cta-bg.svg',
                    logo: svg + 'whatsapp-cta-image.svg',
                    tag: svg + 'whatsapp-cta-tag.svg',
                },
                extension: svg + 'cta-extension.svg',
                app: svg + 'cta-app.svg',
                fiveStars: svg + 'cta-five-stars.svg',
                googlechrome: svg + 'cta-google-chrome.svg',
                googleplay: svg + 'cta-google-play.svg',
                appleStore: svg + 'cta-app-store.svg',
                email: svg + 'cta-email.svg',
            },
        },
        black: {
            plus: svg + 'plus-black.svg',
            comment: svg + 'comment-black.svg',
            menuItems: svg + 'menu-items-black.svg',
            aboutTimer: svg + 'about-timer-black.svg',
            refreshed: svg + 'refreshed-black.svg',
            dropdown: svg + 'dropdown-comments-black.svg',
            local: svg + 'local-black.svg',
            arrowRight: svg + 'arrow-right-solid.svg',
            recentActivity: svg + 'recent-activity-black.svg',
        },
        orange: {
            fire: png + 'fire-orange.png',
            user: png + 'user-normal-orange.png',
            exclusive: svg + 'exclusive-orange.svg',
            gift: svg + 'gift-orange.svg',
        },
        blue: {
            loading: png + 'loading-blue.png',
            chat: svg + 'dashboard-chat-blue.svg',
            arrowLeft: svg + 'arrow-left-blue.svg',
            fire: png + 'fire-blue.png',
            offerFinished: png + 'offer-finished-blue.png',
            userAdmin: png + 'user-admin-blue.png',
            shop: svg + 'shop-blue.svg',
        },
        yellow: {
            hourglass: svg + 'hourglass-yellow.svg',
            coinStack: svg + 'dashboard-coin-stack-yellow.svg',
            coin: svg + 'dashboard-coin-yellow.svg',
            cup: svg + 'dashboard-cup-yellow.svg',
            offerStart: svg + 'offer-start-yellow.svg',
            coinPrize: svg + 'coin-prize-yellow.svg',
        },
        primary: {
            success: primaryColorSVGsPath + 'success.svg',
            pending: primaryColorSVGsPath + 'pending.svg',

            photoUpload: primaryColorSVGsPath + 'photo-upload.svg',
            dashChat: primaryColorSVGsPath + 'dashboard-chat.svg',
            offerPercent: primaryColorSVGsPath + 'offer-percent.svg',
            sponsored: primaryColorSVGsPath + 'sponsored-tag.svg',
            shield: primaryColorSVGsPath + 'shield.svg',
            about: primaryColorSVGsPath + 'about.svg',
            bookmark: primaryColorSVGsPath + 'bookmark.svg',
            referralLink: primaryColorSVGsPath + 'referral-link.svg',
            span: primaryColorSVGsPath + 'span.svg',
            subscribe: primaryColorSVGsPath + 'subscribe.svg',
            copyCode: primaryColorSVGsPath + 'copy-code.svg',
            buttonBendCoupon: primaryColorSVGsPath + 'bend.svg.svg',
            submit: primaryColorSVGsPath + 'submit.svg',
            trash: primaryColorSVGsPath + 'dashboard-trash.svg',
            dashBell: primaryColorSVGsPath + 'dashboard-bell.svg',
            dashCamera: primaryColorSVGsPath + 'dashboard-camera.svg',
            dashShare: primaryColorSVGsPath + 'dashboard-share.svg',
            checkbox: primaryColorSVGsPath + 'dashboard-checkbox.svg',
            unknown: primaryColorSVGsPath + 'dashboard-unknown.svg',
            gear: primaryColorSVGsPath + 'dashboard-gear.svg',
            giftcard: primaryColorSVGsPath + 'dashboard-giftcard.svg',
            dashStatistic: primaryColorSVGsPath + 'dashboard-statistic.svg',
            dashComments: primaryColorSVGsPath + 'dashboard-comments.svg',
            dashCoin: primaryColorSVGsPath + 'dashboard-coin.svg',
            dashCoupon: primaryColorSVGsPath + 'dashboard-coupon.svg',
            dashCup: primaryColorSVGsPath + 'dashboard-cup.svg',
            dashLike: primaryColorSVGsPath + 'dashboard-like.svg',
            dashOffersAndCoupons: primaryColorSVGsPath + 'dashboard-offers-and-coupons.svg',

            verified: primaryColorSVGsPath + 'verified.svg',
            homeMenu: primaryColorSVGsPath + 'home-menu.svg',
            search: primaryColorSVGsPath + 'search.svg',
            coupons: primaryColorSVGsPath + 'dashboard-coupon.svg',
            seeMore: primaryColorSVGsPath + 'see-more.svg',
            pets: primaryColorSVGsPath + 'pets.svg',
            safetyFinance: primaryColorSVGsPath + 'safety-and-finance.svg',
            restaurantFood: primaryColorSVGsPath + 'restaurants-and-food.svg',
            homeGarden: primaryColorSVGsPath + 'home-and-garden.svg',
            sports: primaryColorSVGsPath + 'sports.svg',
            carsBikes: primaryColorSVGsPath + 'cars-and-bikes.svg',
            videogames: primaryColorSVGsPath + 'videogames.svg',
            clothesAccesories: primaryColorSVGsPath + 'clothes-and-accesories.svg',
            familyKids: primaryColorSVGsPath + 'family-kids-and-childrens.svg',
            freetime: primaryColorSVGsPath + 'ocio-y-tiempo-libre.svg',
            free: primaryColorSVGsPath + 'gratis.svg',
            software: primaryColorSVGsPath + 'software.svg',
            books: primaryColorSVGsPath + 'books.svg',
            travels: primaryColorSVGsPath + 'viajes.svg',
            diamond: primaryColorSVGsPath + 'diamond.svg',
            arrowLeft: primaryColorSVGsPath + 'left-arrow-carrousel.svg',
            arrowRight: primaryColorSVGsPath + 'right-arrow-carrousel.svg',
            arrowDown: primaryColorSVGsPath + 'down-arrow.svg',
            arrowRightCategory: primaryColorSVGsPath + 'arrow-right.svg',
            arrowDownDouble: primaryColorSVGsPath + 'arrow-down-double.svg',
            filterBy: primaryColorSVGsPath + 'filter-by.svg',
            shopCheck: primaryColorSVGsPath + 'shop-check.svg',
            ticket: primaryColorSVGsPath + 'ticket.svg',
            shop: primaryColorSVGsPath + 'shop.svg',
            similarCategories: primaryColorSVGsPath + 'similar-categories.svg',
            question: primaryColorSVGsPath + 'question.svg',
            questionMaletin: primaryColorSVGsPath + 'question-maletin.svg',
            questionHowto: primaryColorSVGsPath + 'question-how-to-use.svg',
            paymentMethods: primaryColorSVGsPath + 'payment-methods.svg',
            socialsSidebar: primaryColorSVGsPath + 'socials-sidebar.svg',
            plusRounded: primaryColorSVGsPath + 'plus-rounded.svg',
            star: primaryColorSVGsPath + 'star.svg',
            user: primaryColorSVGsPath + 'user.svg',
            notificationsHeader: primaryColorSVGsPath + 'notifications-header.svg',
            messagesHeader: primaryColorSVGsPath + 'messages-header.svg',
            offer: primaryColorSVGsPath + 'offer.svg',
            coins: primaryColorSVGsPath + 'coins.svg',
            settingsUser: primaryColorSVGsPath + 'settings.svg',
            logout: primaryColorSVGsPath + 'logout.svg',
            share: primaryColorSVGsPath + 'share.svg',
            brandsLike: primaryColorSVGsPath + 'brands-like.svg',
            chat: primaryColorSVGsPath + 'chat.svg',
            information: primaryColorSVGsPath + 'information.svg',
            timeleft: primaryColorSVGsPath + 'timeleft.svg',
            comments: primaryColorSVGsPath + 'comments.svg',
            report: primaryColorSVGsPath + 'report.svg',
            link: primaryColorSVGsPath + 'link.svg',
            crown: primaryColorSVGsPath + 'crown.svg',
            bug: primaryColorSVGsPath + 'bug.svg',
            duplicated: primaryColorSVGsPath + 'duplicated.svg',
            expired: primaryColorSVGsPath + 'expired-offer.svg',
            heart: primaryColorSVGsPath + 'heart.svg',
            ship: primaryColorSVGsPath + 'ship.svg',
            percent: primaryColorSVGsPath + 'percent.svg',
            cash: primaryColorSVGsPath + 'cash.svg',
            calendar: primaryColorSVGsPath + 'calendar.svg',
            valorate: primaryColorSVGsPath + 'valorate.svg',
            valorateFilled: primaryColorSVGsPath + 'valorate-filled.svg',
            download: primaryColorSVGsPath + 'download.svg',
            shipment: primaryColorSVGsPath + 'shipments.svg',
            fullArrowRight: primaryColorSVGsPath + 'full-arrow-right.svg',
            chevronArrowRight: primaryColorSVGsPath + 'circle-chevron-right.svg',
            userAccount: primaryColorSVGsPath + 'account.svg',
            userNotifications: primaryColorSVGsPath + 'user-notifications.svg',
            userMessages: primaryColorSVGsPath + 'user-messages.svg',
            userSaved: primaryColorSVGsPath + 'user-saved.svg',
            userCoins: primaryColorSVGsPath + 'user-coins.svg',
            userThrophies: primaryColorSVGsPath + 'user-trophies.svg',
            userSettings: primaryColorSVGsPath + 'user-settings.svg',
            mail: primaryColorSVGsPath + 'mail.svg',
            bag: primaryColorSVGsPath + 'icon-bag.svg',
            close: primaryColorSVGsPath + 'close.svg',
            loading: png + (variant === 'megadescuentos' ? 'loading-purple.png' : 'loading-blue.png'),
        },
        secondary: {
            contrast: {
                plus: svg + (variant === 'megadescuentos' ? 'plus-white.svg' : 'plus-black.svg'),
            },
            bookmark: secondaryColorSVGsPath + 'bookmark.svg',
            hourglass: secondaryColorSVGsPath + 'hourglass.svg',
            copyCode: secondaryColorSVGsPath + 'copy-code.svg',
            broadcast: secondaryColorSVGsPath + 'dashboard-broadcast.svg',
            promotionsSvg: secondaryColorSVGsPath + 'promotions.svg',
            seeOffer: secondaryColorSVGsPath + 'see-offer.svg',
            offers: secondaryColorSVGsPath + 'offers.svg',
            comments: secondaryColorSVGsPath + 'comments.svg',
            clock: secondaryColorSVGsPath + 'clock.svg',
            magic: secondaryColorSVGsPath + 'magic.svg',
        },
        green: {
            connected: svg + 'dashboard-connected-green.svg',
            verified: svg + 'verified-green.svg',
            notification: svg + 'notification-green.svg',
        },
        white: {
            menuArrow: svg + 'menu-arrow.svg',
            successThing: svg + 'success-white.svg',
            loading: png + 'loading-white.png',
            edit: svg + 'messenger-edit-white.svg',
            arrowRight: svg + 'arrow-rigth-white.svg',
            help: svg + 'help-icon-white.svg',
            finalizeIn: svg + 'finalize-in-white.svg',
            lineThroughBell: svg + 'dashboard-line-through-bell-white.svg',
            bell: svg + 'dashboard-bell-white.svg',
            clock: svg + 'dashboard-clock-white.svg',
            brandsMenu: png + 'brands-menu-white.png',
            categoriesMenu: png + 'categories-menu-white.png',
            categoryMenu: png + 'category-menu-white.png',
            chatMenu: png + 'chat-menu-white.png',
            couponsMenu: png + 'coupons-menu-white.png',
            freeMenu: png + 'free-menu-white.png',
            homeMenu: png + 'home-menu-white.png',

            pencilMenu: png + 'pencil-menu-white.png',
            newsMenu: png + 'news-menu-white.png',
            offersMenu: png + 'offers-menu-white.png',
            trash: png + 'trash-white.png',
            error: png + 'error-white.png',
            errorform: svg + 'icon-error.svg',
            success: png + 'success-white.png',
            warning: png + 'warning-white.png',
            arrowTop: png + 'arrow-top-white.png',
            search: svg + 'search-white.svg',
            close: png + 'close-white.png',
            userHead: svg + 'user-header-white.svg',
            user: png + 'footer1-c-white.png',
            share: png + 'share-white.png',
            seeOffer: svg + 'see-offer-white.svg',
            coupons: svg + 'dashboard-coupon-white.svg',
            shop: svg + 'shop-white.svg',
            offers: svg + 'offers-white.svg',
            menuItems: svg + 'menu-items-white.svg',
            gift: svg + 'surveys-white.svg',
            plus: svg + 'plus-white.svg',
            timeleft: svg + 'timeleft-white.svg',
            ship: svg + 'ship-white.svg',
            logout: svg + 'logout-white.svg',

            createThread: svg + 'create-thread-white.svg',

            answerComment: svg + 'answer-comment-white.svg',
            like: svg + 'like-white.svg',
            equis: svg + 'x-white.svg',
            voteup: svg + 'voteup-white.svg',
            // success: svg + 'success-white.svg',
            successClean: svg + 'success-clean-white.svg',
            expired: svg + 'expired-white.svg',
            popular: svg + 'popular-white.svg',
            chat: svg + 'chat-white.svg',
            minus: svg + 'minus-white.svg',

            bestOffers: svg + 'best-offers-white.svg',
        },
        gray: {
            info: svg + 'info-gray.svg',
            closeModal: svg + 'modal-close-icon-gray.svg',
            backModal: svg + 'modal-back-icon-gray.svg',
            bell: svg + 'bell-gray.svg',
            noComments: svg + 'no-comments-found.svg',
            noRewards: svg + 'no-rewards-found.svg',
            noRewardsWon: svg + 'no-rewards-won-found.svg',
            noSavedOffers: svg + 'no-saved-offers-found.svg',
            noActivities: svg + 'no-activities-found.svg',
            noChats: svg + 'no-chats-found.svg',
            noDiscounts: svg + 'no-discounts-found.svg',
            like: svg + 'like-gray.svg',
            dislike: svg + 'dislike-gray.svg',
            clock: svg + 'clock-gray.svg',
            buttonDots: svg + 'button-dots-gray.svg',
            loading: png + 'loading-gray.png',
            gear: svg + 'gear-gray.svg',
            check: svg + 'check-gray.svg',
            closeRounded: png + 'close-rounded-gray.png',
            close: png + 'close-gray.png',
            messages: svg + 'messages-gray.svg',
            notifications: svg + 'notifications-gray.svg',
            arrowLeft: svg + 'arrow-left-gray.svg',
            arrowRight: svg + 'arrow-right-gray.svg',
            arrowDown: svg + 'arrow-down-gray.svg',
            comment: svg + 'comment-gray.svg',
            fire: png + 'fire-gray.png',
            footer: png + 'footer-gray.png',
            goToOffer: png + 'go-to-offer-gray.png',
            link: png + 'link-gray.png',
            local: png + 'local-gray.png',
            save: png + 'save-gray.png',
            shipment: svg + 'shipments-gray.svg',
            user: png + 'user-gray.png',
            categories: svg + 'categories-gray.svg',
            offerFinishIn: svg + 'offer-finalizes-in-gray.svg',
            refreshedAt: svg + 'refreshed-at.svg',
            mainCardMenu: svg + 'main-card-menu-gray.svg',

            userHeader: svg + 'user-gray.svg',
            notificationsHeader: svg + 'notifications-header-gray.svg',
            messagesHeader: svg + 'messages-header-gray.svg',
            offer: svg + 'offer-gray.svg',
            bookmark: svg + 'bookmark-gray.svg',
            trophy: svg + 'trophy-gray.svg',
            settings: svg + 'settings-gray.svg',
            logout: svg + 'logout-gray.svg',

            eye: svg + 'eye-gray.svg',
            popular: svg + 'popular-gray.svg',
            plusRounded: png + 'plus-rounded-gray.png',

            dropdownMenuDots: svg + 'dropdown-comments-gray.svg',
            rightArrowSmall: svg + 'right-arrow-gray.svg',
            linkSoft: svg + 'link-soft-gray.svg',
            cuponSoft: svg + 'cupon-soft-gray.svg',

            heart: svg + 'heart-gray.svg',
            world: svg + 'world-gray.svg',

            replies: svg + 'answers-gray.svg',
            plane: svg + 'plane-gray.svg',

            lastRefresh: svg + 'last-refresh-gray.svg',
            createdAt: svg + 'created-at-gray.svg',
            readtime: svg + 'readtime-gray.svg',

            tools: svg + 'notifications-page-gray.svg',
            remove: svg + 'remove-gray.svg',
            deactivate: svg + 'deactivate-gray.svg',
            exit: svg + 'exit-gray.svg',
            arrowGrayRight: svg + 'arrow-right.svg',
            mute: svg + 'mute-gray.svg',

            // user menu
            userAccount: svg + 'account-gray.svg',
            userNotifications: svg + 'user-notifications-gray.svg',
            userMessages: svg + 'user-messages-gray.svg',
            userSaved: svg + 'user-saved-gray.svg',
            userCoins: svg + 'user-coins-gray.svg',
            userThrophies: svg + 'user-trophies-gray.svg',
            userSettings: svg + 'user-settings-gray.svg',
            userLogout: svg + 'user-logout-gray.svg',

            noPrizes: svg + 'no-prizes-gray.svg',
            noPrizesAvailable: svg + 'no-prizes-available-gray.svg',
        },
        red: {
            hourglass: svg + 'hourglass-red.svg',
            clock: svg + 'clock-red.svg',
            loading: png + 'loading-red.png',
            disconnected: svg + 'dashboard-disconnected-red.svg',
            formError: svg + 'form-error-red.svg',
            arrowRight: svg + 'arrow-right-red.svg',
            fire: png + 'fire-red.png',
            timeleft: png + 'timeleft-red.png',
            timeleftsvg: svg + 'timeleft-red.svg',
            timer: svg + 'clock.svg',
        },
    }
}
