export default {
    MarkAsRead: {
        path: '/v1/notifications/mark-read',
    },
    muteAction: {
        path: '/v1/notifications/mute-an-action',
    },
    muteModel: {
        path: '/v1/notifications/mute-a-model',
    },
    delete: {
        path: '/v1/notifications',
    },
}
