import { ofetch } from 'ofetch'

export default defineNuxtPlugin((_nuxtApp) => {
    globalThis.$fetch = ofetch.create({
        onRequest({ request, options }) {
            if (import.meta.server) {
                if (!options.headers) {
                    options.headers = {}
                }

                let clientIp: string | undefined

                if (_nuxtApp.ssrContext?.event.node.req.headers['x-forwarded-client-ip']) {
                    clientIp = _nuxtApp.ssrContext?.event.node.req.headers['x-forwarded-client-ip'] as string
                }

                if (_nuxtApp.ssrContext?.event.node.req.headers['x-forwarded-for']) {
                    const forwardedFor = _nuxtApp.ssrContext?.event.node.req.headers[
                        'x-forwarded-for'
                    ] as string
                    clientIp = forwardedFor.split(', ')[0]
                }

                if (clientIp) {
                    options.headers['X-SSR-Request' as keyof HeadersInit] = 'true'
                    options.headers['X-SSR-Client-IP' as keyof HeadersInit] = clientIp
                }
            }
        },
    })
})
