<template>
    <Transition name="fade">
        <div v-if="showLoader" class="site-global-loader">
            <div class="site-global-loader__wrapper">
                <div
                    class="site-global-loader__letter"
                    :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary' : 'bg-white'"
                >
                    <img :src="$assets.brand.loaderLogoLetter" :alt="$lang.brand" />
                </div>
                <span class="site-global-loader__wave"></span>
                <span class="site-global-loader__wave"></span>
                <span class="site-global-loader__wave"></span>
            </div>
        </div>
    </Transition>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const showLoader = ref(false)

const { isLoading } = useRootStore()

/* const {$lang} = useNuxtApp()  */

watch(isLoading, (value) => {
    if (value) start()
    else finish()
})

const start = () => {
    showLoader.value = true
}
const finish = () => {
    showLoader.value = false
}
const nuxtApp = useNuxtApp()

let timer = null as null | NodeJS.Timeout

if (import.meta.client) {
    const unsubLoadingStartHook = nuxtApp.hook('page:loading:start', () => {
        timer = setTimeout(() => {
            start()
            timer = null
        }, 200)
    })
    const unsubLoadingFinishHook = nuxtApp.hook('page:loading:end', () => {
        if (timer) {
            clearTimeout(timer)
            timer = null
        } else finish()
    })
    const unsubError = nuxtApp.hook('vue:error', () => {
        if (timer) {
            clearTimeout(timer)
            timer = null
        } else finish()
    })
}
</script>

<style lang="postcss" scoped>
.site-global-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #d1d1d1ce;

    &__wrapper {
        @apply absolute left-1/2 top-1/2 h-32 w-32 -translate-x-1/2 -translate-y-1/2 -skew-x-2 -skew-y-3 transform;
    }

    &__letter {
        @apply absolute left-2 top-2 block h-20 w-20 transform overflow-hidden rounded-lg;
        box-shadow: 10px 10px 10px theme('colors.site.primary.shadow');
        animation-iteration-count: infinite;
        animation-name: letter-wave;
        animation-duration: 800ms;

        img {
            @apply relative z-20 h-full w-full object-contain p-3;
        }
    }

    &__wave {
        @apply absolute block transform border-b border-r border-black;

        &:nth-of-type(1) {
            @apply bottom-8 left-0 right-8 top-0 rounded-xl border-transparent;
            animation-name: first-wave;
            animation-duration: 800ms;
            animation-delay: 50ms;
            animation-iteration-count: infinite;
        }

        &:nth-of-type(2) {
            @apply bottom-6 left-0 right-6 top-0 rounded-2xl border-transparent;
            animation-name: second-wave;
            animation-duration: 800ms;
            animation-delay: 100ms;
            animation-iteration-count: infinite;
        }

        &:nth-of-type(3) {
            @apply bottom-4 left-0 right-4 top-0 rounded-2xl border-transparent;
            animation-name: third-wave;
            animation-duration: 800ms;
            animation-delay: 150ms;
            animation-iteration-count: infinite;
        }
    }

    @keyframes letter-wave {
        0%,
        100% {
            @apply left-2 top-2 h-20 w-20;
        }
        30% {
            @apply left-0 top-0 h-24 w-24;
        }
    }

    @keyframes first-wave {
        0%,
        100% {
            @apply bottom-8 right-8;
        }
        30% {
            @apply bottom-6 right-6 border-site-primary;
        }
    }
    @keyframes second-wave {
        0%,
        100% {
            @apply bottom-6 right-6;
        }
        30% {
            @apply bottom-4 right-4 border-site-primary;
        }
    }
    @keyframes third-wave {
        0%,
        100% {
            @apply bottom-4 right-4;
        }
        30% {
            @apply bottom-2 right-2 border-site-primary;
        }
    }
}
</style>
