export default {
    coupons: {
        path: '/v1/coupons',
    },
    offers: {
        path: '/v1/offers',
    },

    foros: {
        path: '/v1/forums',
    },
    votes: {
        path: '/v1/votes',
    },
    comments: {
        path: '/v1/comments',
    },
    likes: {
        path: '/v1/likes',
    },
    report: {
        path: '/v1/reports',
    },
    save: {
        path: '/v1/saves',
    },
    expire: {
        path: '/v1/expireds',
    },
}
