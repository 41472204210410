class ScrollObserver {
    lazyComponentsObserverEntryCallback = (entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
            const handler = this.lazyComponentsObserverHandlers.get(entry.target)
            if (handler) {
                handler({
                    target: entry.target,
                    intersected: entry.isIntersecting,
                })
            }
        }
    }

    lazyComponentsObserver: IntersectionObserver = new IntersectionObserver((entries) =>
        entries.forEach(this.lazyComponentsObserverEntryCallback),
    )

    lazyComponentsObserverHandlers: Map<
        Element,
        (payload: { target: Element; intersected: boolean }) => any
    > = new Map()

    addTarget(
        observerMapKey: string,
        target: Element,
        callback: (payload: { target: Element; intersected: boolean }) => any,
    ) {
        if (observerMapKey === 'lazyComponents') {
            this.lazyComponentsObserver.observe(target)
            this.lazyComponentsObserverHandlers.set(target, callback)
        }
    }

    remove(target: Element) {
        const handler = this.lazyComponentsObserverHandlers.get(target)
        if (handler) {
            this.lazyComponentsObserver.unobserve(target)
            this.lazyComponentsObserverHandlers.delete(target)
        }
    }
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            scrollObserver: new ScrollObserver(),
        },
    }
})
