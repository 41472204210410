<template>
    <NuxtLayout name="default">
        <div
            v-if="
                error.statusCode === '404' ||
                error.statusCode === '500' ||
                error.statusCode === 404 ||
                error.statusCode === 500
            "
            class="error-container"
        >
            <div class="page-not-exist">
                <picture class="page-not-exist__number-image">
                    <img :src="$assets.error.number" alt="error 404" />
                </picture>

                <h1>{{ $lang.components.layoutError.no_exist }}</h1>
                <h2>{{ $lang.components.layoutError.description }}</h2>

                <div class="page-not-exist__button">
                    <button
                        v-if="backurl && backurlAvailable"
                        class="page-not-exist__button-back"
                        @click="goback"
                    >
                        {{ $lang.components.layoutError.go_back }}
                    </button>
                    <NuxtLink no-prefetch to="/" class="page-not-exist__button-home" rel="follow">
                        <img :src="$assets.white.seeOffer" alt="Ir a la oferta" />
                        {{ $lang.components.layoutError.go_home }}
                    </NuxtLink>
                </div>
            </div>

            <div class="error-container__cone">
                <img :src="$assets.error.cone" alt="error cono" />
            </div>
        </div>
        <div v-else-if="error.statusCode === '401' || error.statusCode === 401" class="restricted-access">
            <div class="restricted-access__content">
                <h1 class="restricted-access__title">{{ error.message }}</h1>
                <p class="restricted-access__suggestion">
                    {{ $lang.components.layoutError.try_again }}
                    <NuxtLink no-prefetch :to="`/${$lang.routes.contact}`">{{
                        $lang.components.layoutError.send_message
                    }}</NuxtLink
                    >.
                </p>
            </div>
        </div>
    </NuxtLayout>
</template>

<script lang="ts" setup>
const props = defineProps({
    error: {
        type: Object as PropType<{ statusCode: string | number; message: string }>,
        required: true,
    },
})

const backurl = ref(null) as Ref<null | History>
const backurlAvailable = ref(false)

onMounted(() => {
    backurl.value = window.history
    backurlAvailable.value = backurl.value && backurl.value.length > 1
})

const { $lang } = useNuxtApp()

const title = $lang.components.layoutError.title_seo
const description = $lang.components.layoutError.description_seo

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
const goback = () => {
    if (backurl.value) {
        backurl.value.back()
    }
}
</script>

<style lang="postcss" scoped>
.error-container {
    @apply relative flex justify-center bg-site-primary bg-cover p-4 text-white;
    .page-not-exist {
        @apply flex max-w-md flex-col justify-center space-y-6 bg-auto sm:max-w-sm;
        h1 {
            @apply text-3xl font-semibold;
        }
        &__button {
            @apply z-50 flex gap-3;
            &-back {
                @apply rounded-lg bg-site-secondary p-2 hover:bg-site-secondary-hover;
            }
            &-home {
                @apply z-50 flex gap-2 rounded-lg border-2 border-white p-2 text-white;
                img {
                    @apply w-4 self-center;
                }
            }
        }
    }
    &__cone {
        @apply absolute bottom-2 right-2 z-10 w-20 md:bottom-10 md:right-28 md:w-max lg:bottom-3 lg:right-3;
    }
}
.restricted-access {
    @apply container flex items-center justify-center;
    &__content {
        @apply max-w-3xl px-3 py-5;
    }
    &__image {
        @apply mb-5 block h-44;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__title {
        @apply mb-3 text-center text-2xl font-semibold text-gray-800;
    }
    &__suggestion {
        @apply text-center text-gray-800;
        a {
            @apply text-site-primary;
        }
    }
}
</style>
